import React, { useState, useEffect } from 'react'

import { useMapi, Banner, Typography } from '@leshen/gatsby-theme-leshen'

import isCallCenterOpen from '../../../../utils/isOpen'
import CustomLink from './CustomBannerLink'

const GlobalBanner = () => {
  const [callCenterStatus, setCallCenterStatus] = useState(false)

  useEffect(() => {
    // Update callCenterStatus after component mounts
    setCallCenterStatus(isCallCenterOpen())
  }, [])

  const { requestId } = useMapi()

  return (
    <>
      <Banner
        backgroundColor="Primary"
        centerAligned
        layout="100"
        mainContent={
          <>
            {callCenterStatus && (
              <Typography variant="h4">
                If you run into any difficulty, our team of agents, US-based and
                US-licensed, is available to help.
              </Typography>
            )}
            <CustomLink
              callCenterStatus={callCenterStatus}
              variant="feature"
              cta="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
              requestId={requestId}
            />
          </>
        }
      />
    </>
  )
}

export default GlobalBanner
