import React from 'react'
import { Typography, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'
import data from '../data/headerData'
import topBar from '../data/topBarData'
import logo from '../images/header-logo.svg'

const header = () => {
  const newHeaderData = {
    hideLocation: true,
    topBarLinks: topBar,
    topBarBackgroundColor: 'light',
    textBlock: (
      <>
        <LeshenPhoneCTA
          ghost
          leftIcon=""
          cobra="Swap with Cobra CTA"
          color="primary"
        >
          Questions? Ask a licensed agent at
        </LeshenPhoneCTA>
        <Typography variant="">Speak with a licensed agent</Typography>
        <Typography variant="">
          M-F 6:00 a.m. to 5 p.m. MDT, Closed Weekends
        </Typography>
      </>
    ),
    textBlockAlignment: 'right',
    linkGroups: data,
    logo: <img src={logo} alt="Logo" />,
    logoLink: '/',
  }
  return newHeaderData
}

export default header
