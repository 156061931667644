import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useMapi } from '@leshen/gatsby-theme-leshen'
import { ShoppingCart, Phone } from 'emotion-icons/material'

const CustomLink = ({ children, cta, callCenterStatus, color, ...props }) => {
  const { rotatedNumber } = useMapi()
  const [clicked, setClicked] = useState(false)

  const sunfirePixel =
    'https://insight.adsrvr.org/track/pxl/?adv=yzrdth4&ct=0:4wq9ic8&fmt=3'

  return (
    <a
      href={callCenterStatus ? `tel:${rotatedNumber}` : cta}
      className="margin-x-auto call-center-hours-button"
      target={!callCenterStatus && '_blank'}
      style={{ marginTop: '0' }}
      onClick={() => setClicked(true)}
      color={color}
      {...props}
    >
      {callCenterStatus ? (
        <>
          <Phone style={{ marginRight: '5px' }} />
        </>
      ) : (
        <>
          <ShoppingCart style={{ marginRight: '5px' }} />
        </>
      )}

      {clicked && (
        <>
          <img
            height="1"
            width="1"
            style={{ borderStyle: 'none' }}
            alt=""
            src={sunfirePixel}
          />
        </>
      )}
      {callCenterStatus ? `${rotatedNumber}` : 'View Plans'}
    </a>
  )
}

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  cta: PropTypes.string.isRequired,
}

export default CustomLink
