/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import serviceAgent from '../../images/people/agent.png'

import couple from '../../images/people/couple-using-laptop.jpg'
import packingUp from '../../images/people/packing-up.png'
import sepInfographic from '../../images/illustrations/sep-infographic.png'
import forkliftDriver from '../../images/people/forklift-driver.png'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  Banner,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/QuestionsHeader'
import footer from '../../components/Footer'
import GlobalBanner from '../../components/GlobalBanner'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Review qualifying events in a helpful Medicare SEP chart | Healthcareplans.com',
          description:
            'Had a life-changing event and need a new Medicare plan? See if you qualify for a Medicare Special Enrollment Period with help from healthcareplans.com | Healthcareplans.com',
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '169944',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Review qualifying events in a helpful Medicare SEP chart | Healthcareplans.com ',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(brandyList, data.allBrandyDisclaimer.edges),
    main: (
      <>
        <Helmet />
        <VariableContent
          backgroundColor="dark"
          className="text-align-center-desktop"
        >
          <Typography variant="h1" style={{ color: '#ffffff' }}>
            Review our helpful Medicare SEP Chart
          </Typography>
          <Typography
            variant="h4"
            style={{ color: '#ffffff', marginBottom: '0' }}
          >
            Learn about the Medicare Special Enrollment Periods that allow you
            to enroll in a new plan outside of the Annual Enrollment Period.
          </Typography>
        </VariableContent>

        <SplitContent
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={couple}
              alt="a customer service agent sitting at desktop with computer and headset"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Key Takeaways:</Typography>
              <List>
                <ListItem>
                  A Medicare Special Enrollment Period (SEP) is triggered by a
                  certain event and allows for enrolling, changing, or dropping
                  Medicare coverage outside of standard enrollment periods.
                </ListItem>
                <ListItem>
                  Common SEP triggers include moving to an area where your
                  Medicare coverage is no longer valid or losing other medical
                  coverage.
                </ListItem>
                <ListItem>
                  The length of a Special Enrollment Period depends on the
                  triggering event.
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="body">
                While the primary time to enroll in a Medicare plan is the
                Annual Enrollment Period (AEP) lasting from October 15 through
                December 7 each year, certain circumstances can trigger a
                Special Enrollment Period (SEP), which allows you to enroll or
                change your plan outside of AEP. Read on to learn more about the
                events that can trigger an SEP and view a comprehensive chart
                listing all of these events below.
              </Typography>
              <Typography variant="h2">
                What is a Medicare Special Enrollment Period (SEP)?
              </Typography>
              <Typography variant="body">
                Simply put, a Medicare Special Enrollment Period (SEP) is an
                enrollment period outside of the usual Annual Enrollment Period
                when you can enroll in Medicare plans or change your coverage.
                Special Enrollment Periods are triggered by certain events.
                Common SEP triggers include moving to an area where your
                Medicare coverage is no longer valid or losing other medical
                coverage. A comprehensive list and short but detailed
                explanations follow.
              </Typography>
              <Typography variant="h3">What events trigger an SEP?</Typography>
              <Typography variant="body">
                The events that trigger a Special Enrollment Period (SEP) fall
                under five major categories{' '}
                <a
                  target="_blank"
                  href="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods"
                >
                  according to the official government resource at Medicare.gov
                </a>
                :
              </Typography>
              <List>
                <ListItem>You change where you live</ListItem>
                <ListItem>You lose your current coverage</ListItem>
                <ListItem>New coverage becomes available to you</ListItem>
                <ListItem>
                  Your plan changes its contract with Medicare
                </ListItem>
                <ListItem>Special situations and exceptions</ListItem>
              </List>
              <Typography variant="body">
                Within these five categories are the specific triggering events.
              </Typography>
              <img
                src={packingUp}
                alt="a customer service agent sitting at desktop with computer and headset"
              />
            </>
          }
        />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h3">You changed where you live</Typography>
            </>
          }
          className="padding-bottom-40 heading-small-margin-bottom"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      During this SEP, you can switch to a new Medicare
                      Advantage Plan or Medicare drug plan. If you tell your
                      plan before your move, your Special Enrollment Period
                      encompasses the month you move plus the following two
                      months. If you tell your plan after you move, your SEP
                      begins the month you tell your plan plus the following two
                      months.
                    </Typography>
                  ),
                  title:
                    'You moved to a new address that isn’t in your plan’s service area.',
                },
                {
                  content: (
                    <>
                      <Typography>
                        During this SEP, you can switch to a new Medicare
                        Advantage Plan or Medicare drug plan. If you tell your
                        plan before your move, your Special Enrollment Period
                        encompasses the month you move plus the following two
                        months. If you tell your plan after you move, your SEP
                        begins the month you tell your plan plus the following
                        two months.
                      </Typography>
                    </>
                  ),
                  title:
                    'You moved to a new address that’s still in your plan’s service area but you have new plan options in your new location.',
                },
                {
                  content: (
                    <Typography>
                      A Medicare beneficiary is someone who is entitled to
                      Medicare benefits. Medicare is primarily for people 65 and
                      older, but can also be available to younger people with
                      illnesses like ESRD and ALS.
                    </Typography>
                  ),
                  title: 'What is a Medicare beneficiary?',
                },
                {
                  content: (
                    <>
                      <Typography>
                        During this SEP, you can switch to a new Medicare
                        Advantage Plan or Medicare drug plan. Your Special
                        Enrollment Period lasts for two full months after the
                        month you move back to the U.S.
                      </Typography>
                    </>
                  ),
                  title:
                    'You moved back to the U.S. after living outside the country.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage Plan or
                      Medicare drug plan, switch from your current Medicare
                      Advantage Plan or Medicare drug plan to another plan, drop
                      your Medicare Advantage plan and return to Original
                      Medicare, or drop your Medicare prescription drug
                      coverage. Your Special Enrollment Period lasts as long as
                      you live in the institution and for two full months after
                      leaving.
                    </Typography>
                  ),
                  title:
                    'You just moved into, currently live in, or just moved out of an institution such as a skilled nursing facility or long-term care hospital.',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          backgroundColor="light"
          mainContent={
            <Typography variant="h3">You lost your current coverage</Typography>
          }
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage Plan or
                      Medicare drug plan, switch from your current Medicare
                      Advantage Plan or Medicare drug plan to another plan, drop
                      your Medicare Advantage plan and return to Original
                      Medicare, or drop your Medicare prescription drug
                      coverage. Your Special Enrollment Period lasts for three
                      full months from either the date you are no longer
                      eligible for Medicaid or the date you are notified,
                      whichever is later.
                    </Typography>
                  ),
                  title: 'You are no longer eligible for Medicaid.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage Plan or
                      Medicare drug plan. Your Special Enrollment Period lasts a
                      full two months after the month your coverage ends.
                    </Typography>
                  ),
                  title:
                    'You left coverage provided through your employer or union (including COBRA coverage).',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can enroll in a Medicare Advantage
                      plan with drug coverage or a Medicare prescription drug
                      plan. Your Special Enrollment Period lasts for three full
                      months from either the date you lose your coverage or the
                      date you are notified, whichever is later.
                    </Typography>
                  ),
                  title:
                    'You involuntarily lost other drug coverage that is as good as Medicare coverage (creditable coverage), or your coverage changed and is no longer creditable.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare drug plan. Your
                      SEP lasts for two full months after you drop your Medicare
                      Cost Plan.
                    </Typography>
                  ),
                  title:
                    'You had drug coverage through a Medicare Cost Plan and left the plan.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage Plan or
                      Medicare drug plan. Your Special Enrollment Period lasts a
                      full two months after the month you drop your PACE plan.
                    </Typography>
                  ),
                  title:
                    'You dropped your Program of All-inclusive Care for the Elderly (PACE) plan coverage.',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          mainContent={
            <Typography variant="h3">
              You have a chance to get other coverage
            </Typography>
          }
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      During this SEP, you can join, switch, or drop your
                      Medicare Advantage Plan or Medicare drug coverage. These
                      Special Enrollment Periods allow for one change each
                      between January and March, April and June, and July and
                      September. Any changes made take effect on the first day
                      of the following month.
                    </Typography>
                  ),
                  title:
                    'You have a chance to enroll in other coverage offered by your employer or union.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can drop your current Medicare
                      Advantage Plan with drug coverage or your Medicare drug
                      plan. This SEP can be triggered anytime.
                    </Typography>
                  ),
                  title:
                    'You have or are enrolling in other Medicare-equivalent drug coverage (such as TRICARE or VA coverage).',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can drop your current Medicare
                      Advantage Plan or your Medicare drug plan. This SEP can be
                      triggered anytime.
                    </Typography>
                  ),
                  title:
                    'You enrolled in a Program of All-inclusive Care for the Elderly (PACE) plan.',
                },
                {
                  content: (
                    <Typography>
                      Medicare uses customer satisfaction surveys and
                      information from healthcare providers to rate plans from
                      1-5 stars, with 5 being the best. Ratings change each
                      year, meaning the availability of 5-star rated plans in
                      your area may change, with plans newly rated 5 stars
                      appearing yearly. You can use this Special Enrollment
                      Period to enroll in a plan with a 5-star quality rating
                      once between December 8 and November 30 of the following
                      year.
                    </Typography>
                  ),
                  title:
                    'You have the chance to enroll in a 5-star Medicare Advantage plan, Medicare drug plan, or Medicare Cost Plan.',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          backgroundColor="light"
          mainContent={
            <Typography variant="h3">
              Your plan changes its contract with Medicare
            </Typography>
          }
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      During this SEP, you can switch from your Medicare
                      Advantage Plan or Medicare drug plan to another plan.
                      Medicare determines the SEP’s length on a case-by-case
                      basis.
                    </Typography>
                  ),
                  title:
                    'Medicare takes an official action (called a “sanction”) because of a problem with a plan that affects you.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can switch from your Medicare
                      Advantage Plan or Medicare drug plan to another plan. The
                      SEP begins two months before the contract ends and ends
                      one full month after the contract ends.
                    </Typography>
                  ),
                  title: 'Medicare terminates your plan’s contract.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join either a Medicare drug plan
                      or a Medicare Advantage Plan with drug coverage. This
                      change can be made once per calendar year.
                    </Typography>
                  ),
                  title:
                    'Your Medicare Advantage plan, Medicare Prescription (Part D) plan, or Medicare Cost plan contract with Medicare is not renewed.',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          mainContent={
            <Typography variant="h3">
              Special situations and exceptions
            </Typography>
          }
          className="faq-section-padding"
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  content: (
                    <Typography>
                      During this SEP, you can join, switch, or drop your
                      Medicare Advantage Plan or Medicare drug coverage. These
                      Special Enrollment Periods allow for one change each
                      between January and March, April and June, and July and
                      September. Any changes made take effect on the first day
                      of the following month.
                    </Typography>
                  ),
                  title: 'You are eligible for both Medicare and Medicaid.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join, switch, or drop your
                      Medicare drug coverage. These Special Enrollment Periods
                      allow for one change each between January and March, April
                      and June, and July and September. Any changes made take
                      effect on the first day of the following month.
                    </Typography>
                  ),
                  title:
                    'You qualify for Extra Help paying for Medicare prescription drug coverage.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join either a Medicare drug plan
                      or a Medicare Advantage Plan with drug coverage. This
                      change can be made once per calendar year.
                    </Typography>
                  ),
                  title:
                    'You are enrolled in a State Pharmaceutical Assistance Program (SPAP).',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join either a Medicare drug plan
                      or a Medicare Advantage Plan with drug coverage. Your
                      Special Enrollment Period begins either the month you lose
                      your eligibility or the month you’re notified, whichever
                      is later, and ends two full months later.
                    </Typography>
                  ),
                  title:
                    'You lose State Pharmaceutical Assistance Program (SPAP) eligibility.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can drop your Medicare Advantage Plan
                      and enroll in Original Medicare with the special rights to
                      buy a Medigap policy. This Special Enrollment Period lasts
                      for 12 months after you enroll in the Medicare Advantage
                      plan for the first time.
                    </Typography>
                  ),
                  title:
                    'You dropped a Medigap policy the first time you joined a Medicare Advantage Plan and are still in a trial period and eligible for the guaranteed issue of a Medigap policy.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Chronic Care
                      Special Needs Plan (SNP). You can join anytime but once
                      you join your chance to make changes during this Special
                      Enrollment Period ends.
                    </Typography>
                  ),
                  title:
                    'You have a severe or disabling condition and there’s a Medicare Chronic Care Special Needs Plan (SNP) available that serves people with your condition.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can switch from a Special Needs Plan
                      (SNP) to a Medicare Advantage Plan or Medicare drug plan.
                      This Special Enrollment Period starts when you lose your
                      special needs status and lasts up to three months after
                      the end of your SNP’s grace period.
                    </Typography>
                  ),
                  title:
                    'You’re enrolled in a Special Needs Plan (SNP) and no longer have the condition that qualifies as the special need the plan serves.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage Plan or
                      Medicare drug plan, switch from your current Medicare
                      Advantage Plan or Medicare drug plan to another plan, drop
                      your Medicare Advantage plan and return to Original
                      Medicare, or drop your Medicare prescription drug
                      coverage. Your Special Enrollment Period lasts two full
                      months after Medicare notifies you of the error.
                    </Typography>
                  ),
                  title:
                    'You joined a plan or chose not to join a plan due to an error by a federal employee.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage plan
                      with drug coverage or a Medicare prescription drug plan.
                      Your Special Enrollment Period lasts two full months after
                      Medicare notifies you of the error.
                    </Typography>
                  ),
                  title:
                    'You weren’t properly told that your other private drug coverage was not Medicare-equivalent.',
                },
                {
                  content: (
                    <Typography>
                      During this SEP, you can join a Medicare Advantage plan
                      with drug coverage or a Medicare prescription drug plan.
                      Your Special Enrollment Period lasts two full months after
                      Medicare or your private carrier notifies you of the
                      error.
                    </Typography>
                  ),
                  title:
                    'You weren’t properly told that you were losing Medicare-equivalent private drug coverage.',
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent alignMainContent="left" backgroundColor="light">
          <Typography variant="h2">
            Special Enrollment Period Triggering Events
          </Typography>
          <img
            src={sepInfographic}
            alt="Special Enrollment Period Infographic"
          />
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h2">
                A common SEP: Continuing work after 65 before retiring
              </Typography>
              <Typography variant="body">
                Many people decide to continue work after age 65 to receive full
                Social Security Benefits. While you can keep your
                employer-provided health coverage during this time, it’s
                essential to enroll in Medicare Part A and a Medicare-equivalent
                drug plan during the Initial Enrollment Period (three months
                before the month of your 65th birthday, the month of your 65th
                birthday, and three months after). Enrolling in Part A during
                your Initial Enrollment Period prevents you from incurring
                penalties when you fully enroll in Medicare during the Special
                Enrollment Period triggered by your retirement.
              </Typography>
              <Typography variant="body">
                The SEP triggered by retirement begins as soon as you stop
                working, even if you choose COBRA or other non-Medicare
                coverage, and lasts for eight months. Your coverage begins the
                month after Social Security gets your completed forms. During
                this SEP, you will either complete your enrollment in Original
                Medicare (Part B in addition to the Part A you already enrolled
                in) in combination with a Medigap and/or Medicare Part D plan or
                enroll in a private Medicare Advantage plan.
              </Typography>
              <img
                src={forkliftDriver}
                alt="forklift driver"
                style={{ marginBottom: '20px' }}
              />
              <Typography variant="h2">What can I do during an SEP?</Typography>
              <Typography variant="body">
                Depending on what triggers your Special Enrollment Period (SEP),
                you can add or drop different aspects of your Medicare coverage.
                Here are examples of what you can do during Special Enrollment
                Periods:
              </Typography>
              <List>
                <ListItem>Join a Medicare Advantage plan</ListItem>
                <ListItem>Join a Medicare prescription drug plan</ListItem>
                <ListItem>
                  Switch from your current plan to another plan
                </ListItem>
                <ListItem>
                  Drop a Medicare Advantage plan and return to Original Medicare
                </ListItem>
                <ListItem>Drop Medicare prescription drug coverage</ListItem>
              </List>
              <Typography variant="body">
                For more detail on what you can do during your SEP, find the
                specific triggering event and related info above.
              </Typography>
              <Typography variant="h2">
                How long do I have to enroll during an SEP?
              </Typography>
              <Typography variant="body">
                The length of time you have to make changes during a Special
                Enrollment Period depends on the event that triggers your SEP.
                Special Enrollment Periods can be as long as twelve months and
                as short as two, with many SEPs’ lengths determined at
                Medicare’s discretion. 
              </Typography>
              <Typography variant="body">
                See details of the timing associated with your particular
                triggering event above.
              </Typography>
              <Typography variant="h2">
                How do I change my Medicare plan during an SEP?
              </Typography>
              <Typography variant="body">
                During a Special Enrollment Period, instructions on what to do
                vary depending on whether you’re adding or dropping coverage,
                joining a Medicare Advantage plan or Original Medicare, or
                enrolling in a Special Needs Plan. For example, if you intend to
                drop your Medicare Advantage Plan and return to Original
                Medicare, you’ll need to follow the directions at{' '}
                <a href="https://www.ssa.gov/benefits/medicare" target="_blank">
                  www.ssa.gov/benefits/medicare.
                </a>
              </Typography>
              <Typography variant="body">
                If you plan on moving from Original Medicare to a Medicare
                Advantage plan through a private provider, on the other hand, we
                recommend working with an insurance agency like ours. Here are
                just a few of the benefits of working with our agency:
              </Typography>
              <List>
                <ListItem>Our agents are U.S.-based and U.S.-licensed</ListItem>
                <ListItem>
                  We represent several different insurance providers and can
                  offer unbiased help finding the plan that fits your needs and
                  your budget
                </ListItem>
                <ListItem>
                  You can browse plans on your own to compare differences and
                  get transparency in plan offerings
                </ListItem>
                <ListItem>You can enroll online or over the phone</ListItem>
              </List>
              <Typography variant="body">
                If you qualify for a Special Enrollment Period and would like
                help finding a new plan, you probably have questions. Sometimes
                it’s easiest to talk to an expert—get in touch with our team by
                phone at <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a> or
                browse available plans online.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Helpful resources:</Typography>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods">
                https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods
              </Link>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/medicare-basics/working-past-65/getting-medicare-when-you-retire">
                https://www.medicare.gov/basics/get-started-with-medicare/medicare-basics/working-past-65/getting-medicare-when-you-retire
              </Link>
              <Link to="https://www.ssa.gov/medicare">
                https://www.ssa.gov/medicare
              </Link>
              <Link to="https://www.ssa.gov/pubs/EN-05-10012.pdf">
                https://www.ssa.gov/pubs/EN-05-10012.pdf 
              </Link>
            </>
          }
        />

        <SplitContent
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={serviceAgent}
              alt="a customer service agent sitting at desktop with computer and headset"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Who We Are</Typography>
              <Typography variant="body">
                Healthcareplans.com partners with leading insurance brands to
                help consumers compare and select plans during Medicare’s Annual
                Enrollment Period, Open Enrollment Period, and Special
                Enrollment Periods.
              </Typography>
              <Typography variant="body">
                Working with Clearlink Insurance Agency, Healthcareplans.com
                connects you with the information needed to make an informed
                Medicare decision.
              </Typography>
              <Typography variant="body">
                To compare plans, get started below.
              </Typography>

              <LinkButton
                sunfirePixel
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/"
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Get Started
              </LinkButton>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos bg-lightgray"
        >
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            Our Insurance Brand Partners
          </Typography>
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <GlobalBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
